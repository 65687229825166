import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/LitreryClub/2023/interview/1.jpg';
import p2 from 'assests/Photos/Clubs/LitreryClub/2023/interview/2.jpg';
import p3 from 'assests/Photos/Clubs/LitreryClub/2023/interview/3.jpg';
import p4 from 'assests/Photos/Clubs/LitreryClub/2023/interview/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { LiterarySidebar } from 'views/Literaryclub2023/components';

const Interview2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
              {
                    src: p4,
                    source: p4,
                    rows: 1,
                    cols: 2,
                }, 
                {
                    src: p3,
                    source: p3,
                    rows: 1,
                    cols: 2,
                }, 
          {
                    src: p1,
                    source: p1,
                    rows: 1,
                    cols: 2,
          },
          {
                    src: p2,
                    source: p2,
                    rows: 1,
                    cols: 2,
          },
     
    ];

    return (
        <Main>

            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                Interview of Famous Personalities    
                                </Typography>
                              
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9 & 10  Date: 31 OCTOBER 2023
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "Poetry comes alive to me through recitation!"
                                <br />
                            </Typography> */}
                            <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Literary Association of National Public School, Yeshwanthpur organized the activity Interview of Famous Personalities on 31st October 2023 for the students of classes 9 and 10. Interview develops essential skills, boosts confidence and prepares them for real-world interviews. Interview activities bridge the gap between theory and practice. They allow students to apply what they have learned in class to a real-world scenario, making the learning experience more relevant and engaging. The objective was impressively attained as we witnessed students conducting the interview of famous personalities using interesting and logical questions. The activity was helpful for students in developing their language skills and understanding the importance of effective team work. It was truly an enriching and enjoyable experience.
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "Interviewing is a skill that takes many years to perfect and most of us are still mastering the art"
                                <br />
                            </Typography>
                            </Box>
                            
                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 500}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                                    <Sidebar2023/>
                        </Box>
                        <Box marginBottom={4}>
                            <LiterarySidebar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default Interview2023;